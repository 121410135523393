import { createColumnHelper } from '@tanstack/react-table'
import Main from '../components/Main'
import Table from '../components/Table'
type Person = {
    eyup: string
    goksel: string
}
const defaultData: Person[] = [
    {
        eyup: 'tanner',
        goksel: 'linsley',
    },
    {
        eyup: 'tanner',
        goksel: 'arda',
    }
];

const columnHelper = createColumnHelper<Person>()

const columns = [
    columnHelper.accessor('eyup', {
        header: () => <span>Eyüp</span>,
        cell: info => info.getValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('goksel', {
        cell: info => <i>{info.getValue()}</i>,
        header: () => <span>Göksel</span>,
        footer: info => info.column.id,
    })
]

const myPersonType: Person = {
    eyup: '',
    goksel: '',
};
const App = () => {
    return (
        <Main>
            {/* {Table({ defaultData, columns }, myPersonType,'Kullanıcılar')} */}
        </Main>
    )
}
export default App;