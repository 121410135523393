import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/global/plugins/font-awesome/css/font-awesome.min.css';
import '../assets/global/plugins/simple-line-icons/simple-line-icons.min.css';
import '../assets/global/plugins/bootstrap/css/bootstrap.min.css';
import '../assets/global/plugins/bootstrap-switch/css/bootstrap-switch.min.css';
import '../assets/global/css/components.min.css';
import '../assets/global/css/plugins.min.css';
import '../assets/pages/css/login.min.css';

import Logo from '../assets/images/icon.png'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { LoginRequest, login } from '../webservice';
import { getToken, setLogin, showToastError, showToastSuccess } from '../resources/func';



interface FormValues {
  kullanici_mail: string;
  parola: string;
}
const currentYear = new Date().getFullYear();
const validationSchema = Yup.object({
  kullanici_mail: Yup.string().email('Geçerli bir e-posta giriniz.').required('E-Posta zorunlu alan.'),
  parola: Yup.string().required('Parola zorunlu alan.'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Giriş Yap';
    document.body.classList.add('login');
    return () => {
      document.body.classList.remove('login');
    };
  }, []);

  const initialValues: FormValues = {
    kullanici_mail: '',
    parola: '',
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

    setSubmitting(false);

    const loginRequest: LoginRequest = {
      email: values.kullanici_mail,
      password: values.parola
    }
    login(loginRequest).then(data => {
      console.log(data.AccessToken);
      setLogin(data.AccessToken);
      const a = getToken;
      const b = localStorage.getItem('token');

      showToastSuccess(`Hoşgeldiniz ${data.TxtKullaniciAd} Anasayfaya Yönlendiriliyorsunuz!`);
      navigate('/anasayfa');
    }).catch(err => {
      if (err?.statusCode === 401) {
        showToastError(`Kullanıcı Mail veya Parolası Hatalı!`);
      }else{
        showToastError(`Sunucu ile bağlantı kurulamadı!`);
      }

    })
  };

  return (
    <>
      <div className="logo">
        <a href="http://mmyazilim.com" target="_blank">
          <img src={Logo} alt="" /> </a>
      </div>
      <div className="content">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, errors, touched }) => (

            <Form className="login-form" id="giris-formu">
              <h3 className="form-title font-green">Giriş Yap</h3>


              {(errors.kullanici_mail && touched.kullanici_mail) || (errors.parola && touched.parola) ? (
                <div className="alert alert-danger">

                  <span>
                    {errors.kullanici_mail && touched.kullanici_mail && errors.kullanici_mail && <ErrorMessage name="kullanici_mail" />}
                    {errors.parola && touched.parola && <ErrorMessage name="parola" />}
                  </span>
                </div>
              ) : null}
              <div className="form-group">
                <label className="control-label visible-ie8 visible-ie9">Mail</label>
                <Field
                  className="form-control form-control-solid placeholder-no-fix"
                  type="text"
                  autoComplete="off"
                  placeholder="Mail"
                  name="kullanici_mail"
                />

              </div>
              <div className="form-group">
                <label className="control-label visible-ie8 visible-ie9">Şifre</label>
                <Field
                  className="form-control form-control-solid placeholder-no-fix"
                  type="password"
                  autoComplete="off"
                  placeholder="Şifre"
                  name="parola"
                />

              </div>
              <div className="form-actions">
                <button type="submit" className="btn green uppercase" disabled={isSubmitting}>
                  Giriş
                </button>
                <a href="javascript:;" id="forget-password" className="forget-password">
                  Parolamı unuttum?
                </a>
              </div>
              <div className="login-options">
                <h4>Bize ulaşın</h4>
                <ul className="social-icons">
                  <li>
                    <a className="social-icon-color facebook" data-original-title="facebook" href="javascript:;"></a>
                  </li>
                  <li>
                    <a className="social-icon-color twitter" data-original-title="Twitter" href="javascript:;"></a>
                  </li>
                  <li>
                    <a className="social-icon-color googleplus" data-original-title="Goole Plus"
                      href="javascript:;"></a>
                  </li>
                  <li>
                    <a className="social-icon-color linkedin" data-original-title="Linkedin" href="javascript:;"></a>
                  </li>
                </ul>
              </div>
              <div className="create-account">
                <p>{/* Hesap oluşturma metni */}</p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="copyright">
        {currentYear} © Coozum Admin Panel
      </div>

    </>
  );
};

export default LoginPage;


