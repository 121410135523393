import React from 'react';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import UserPage from './pages/User';
import Log from './pages/Log';
import LogEkle from './pages/Log.Ekle';
import LogDuzenle from './pages/Log.Duzenle';
import LoadingPage from './pages/Loading';
import Authentication from './components/Authentication'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';

const router = createBrowserRouter([
  {
    element:<Authentication />,
    children:[
      {
        path:'/',
        element:<LoadingPage/>
      },
      {
        path: "/giris",
        element: <LoginPage />
      },
      {
        path:'/anasayfa',
        element: <HomePage />
      },
      {
        path:'/log',
        element: <Log />
      },{
        path:'/log/ekle',
        element: <LogEkle />
      },{
        path:'/log/duzenle/:id',
        element: <LogDuzenle />
      }
    ]
  },
  
]);

const App = () =>{
  return (
    <RouterProvider router={router} />
  );
};

export default App;
