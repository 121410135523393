import { useEffect } from "react";

import Logo from '../assets/images/icon.png'
import User from '../assets/images/user.png'
import { logout } from "../resources/func";
import {  useNavigate,Link } from "react-router-dom";
const App = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (document.body.classList.contains('page-sidebar-closed')) {
            const sidebarMenu = document.querySelector('.page-sidebar-menu');
            if (sidebarMenu) {
                sidebarMenu.classList.add('page-sidebar-menu-closed');
            }
        }
        return () => {
        };
    }, []);
    const toggleMenu = () => {
        document.body.classList.toggle('page-sidebar-closed');
        const sidebarMenu = document.querySelector('.page-sidebar-menu');
        if (sidebarMenu) {
            sidebarMenu.classList.toggle('page-sidebar-menu-closed');
        }
    };
    const responsiveMenu = () => {
        const sidebar = document.querySelector(".page-sidebar");
        if (sidebar) {
            sidebar.classList.toggle("in");
        }
    };
    const toggleDropdown = () => {
        const dropdownUser = document.querySelector('.dropdown-user');
        if (dropdownUser) {
            dropdownUser.classList.toggle('open');
        }
    };
    const logOut = () => {
        logout();
        navigate('/giris')
    }
    return (
        <div className="page-header navbar navbar-fixed-top">
            <div className="page-header-inner ">

                <div className="page-logo">
                    <Link to="/anasayfa">
                        <img src={Logo} alt="logo" className="logo-default" /> </Link>
                    <div className="menu-toggler sidebar-toggler" onClick={toggleMenu}>
                        <span></span>
                    </div>
                </div>

                <a href="javascript:;" className="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse" onClick={responsiveMenu}>
                    <span></span>
                </a>

                <div className="top-menu">
                    <ul className="nav navbar-nav pull-right">

                        <li className="dropdown dropdown-user" onClick={toggleDropdown}>
                            <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
                                data-close-others="true">
                                <img alt="" className="img-circle" src={User} />
                                <span className="username username-hide-on-mobile"> @USERNAME </span>
                                <i className="fa fa-angle-down"></i>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-default">

                                <li>
                                    <a href="admin/admin_liste.php">
                                        <i className="icon-user"></i> Kullanıcılar </a>
                                </li>

                                <li>
                                    <a onClick={logOut}>
                                        <i className="icon-key"></i> Çıkış Yap
                                    </a>
                                </li>
                            </ul>

                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default App;