import React, { useState, useEffect } from 'react';
import Main from './Main';
import '../assets/global/plugins/datatables/plugins/bootstrap/datatables.bootstrap.css';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  createColumnHelper,
  FilterFn,
  flexRender
} from '@tanstack/react-table';
import {
  RankingInfo,
  rankItem,
} from '@tanstack/match-sorter-utils';



type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: DebouncedInputProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}

type AppProps<T> = {
  defaultData: T[];
  columns: any[]; // Adjust the type based on the actual type of columns
};


function App<T>(props: { defaultData: T[]; columns: any[]; onCustomEvent:()=>void }, title: string) {

  const { defaultData, columns } = props;
  const data = defaultData;
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [portletOpen,setPortletOpen]=useState(true);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  });

const closePortlet = ()=>{
  setPortletOpen(!portletOpen);
}
const handleCustomEvent = () => {
  // Bu işlev, dışarıdan iletilen olayı ele alacak
  if (props.onCustomEvent) {
    props.onCustomEvent();
  }
};
  return (
    <div className='row'>
      <div className="col-md-12 col-sm-12">

        <div className="portlet box blue-chambray">
          <div className="portlet-title">
            <div className="caption">
              {title}
            </div>
            <div className="tools">
              <a onClick={closePortlet} className="collapse" data-original-title="" title=""> </a>
              <a onClick={handleCustomEvent} className="reload" data-original-title="" title=""> </a>
            </div>
          </div>
          <div className="portlet-body" style={{display: portletOpen ? 'block':'none'}}>
            <div id="sample_3_wrapper" className="dataTables_wrapper no-footer">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="dataTables_length" id="sample_3_length">
                    <label>Göster
                      <select name="sample_3_length" aria-controls="sample_3" style={{ marginLeft: 5 }} className="form-control input-sm input-xsmall input-inline"
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                          table.setPageSize(Number(e.target.value))
                        }}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div id="sample_3_filter" className="dataTables_filter">
                    <label>Arama:
                      <DebouncedInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} type="search" className="form-control input-sm input-small input-inline" placeholder="" aria-controls="sample_3" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="table-scrollable">
                <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer" id="sample_3" role="grid" aria-describedby="sample_3_info">

                  <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id} role="row">
                        {headerGroup.headers.map(header => (
                          <th key={header.id} className="table-checkbox sorting_disabled" rowSpan={1} colSpan={1} aria-label="" >
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map(row => (
                      <tr key={row.id} className="gradeX odd" role="row">
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-5 col-sm-5">
                  <div className="dataTables_info" id="sample_3_info" role="status" aria-live="polite">
                    {`${defaultData.length} Kayıttan ${table.getState().pagination.pageIndex + 1}-${(table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize} arasındaki kayıtlar gösteriliyor.`}
                  </div>
                </div>
                <div className="col-md-7 col-sm-7">
                  <div className="dataTables_paginate paging_bootstrap_number" id="sample_3_paginate">
                    <ul className="pagination" >
                      <li className={`prev ${!table.getCanPreviousPage() ? 'disabled' : 'active'}`} >
                        <a href="#" title="Prev" onClick={() => table.previousPage()} >
                          <i className="fa fa-angle-left"></i>
                        </a>
                      </li>
                      {
                        Array.from(Array(table.getPageCount())).map((val, i) => (
                          <li key={i} className={table.getState().pagination.pageIndex == i ? 'active' : ''}>
                            <a onClick={() => table.setPageIndex(i)}>{i + 1}</a>
                          </li>
                        ))
                      }
                      <li className={`next ${!table.getCanNextPage() ? 'disabled' : 'active'} `}>
                        <a href="#" title="Next"><i className="fa fa-angle-right"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
