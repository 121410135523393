import { useEffect, useState } from 'react'
import Main from '../components/Main'
import Table from '../components/Table'
import { LogResponse, getAllLogs } from '../webservice/index'
import { createColumnHelper } from '@tanstack/react-table'
import { deleteLog } from '../webservice/log'
import { showToastSuccess } from '../resources/func'
import { Link } from 'react-router-dom'

const App = () => {
    const [allLogs, setAllLogs] = useState<LogResponse[]>([]);
    const columnHelper = createColumnHelper<LogResponse>()
    const columns = [
        columnHelper.accessor('LngKod', {
            header: () => <span>Lng Kod</span>,
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('TxtBaslik', {
            cell: info => <i>{info.getValue()}</i>,
            header: () => <span>Başlık</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('TxtAciklama', {
            cell: info => <i>{info.getValue()}</i>,
            header: () => <span>Açıklama</span>,
            footer: info => info.column.id,
        }),
        {
            accessor: 'Islemler',
            id: 'islemler',
            header: () => <span>İşlemler</span>,
            cell: (info: any) => (
                <>
                    <a onClick={() => handleButtonClick(info.row.original.LngKod)} className="btn btn-outline btn-circle dark btn-sm red">
                        <i className="fa fa-trash-o"></i> Sil
                    </a>
                    <Link to={`duzenle/${info.row.original.LngKod}`} className="btn btn-outline btn-circle dark btn-sm blue">
                        <i className="fa fa-edit"></i> Düzenle
                    </Link>
                </>
            ),
        }
    ];

    function handleButtonClick(lngKod: number) {
        const token = localStorage.getItem('token');
        if (token) {
            deleteLog(token, lngKod).then(data => {
                if (data === 200) {
                    const logs = allLogs.filter(x => x.LngKod != lngKod);
                    setAllLogs(logs);
                    showToastSuccess('Log silme başarılı');
                }
            })
        }
    }
    const getAll = () => {
        const token = localStorage.getItem('token');
        if (token) {
            getAllLogs(token).then(data => {
                setAllLogs(data);
            })
        }
    }
    useEffect(() => {
        getAll();
    }, [])
    const refresh = () => {
        getAll();
    }
    return (
        <Main>
            {Table({ defaultData: allLogs, columns, onCustomEvent: refresh }, 'Loglar')}
        </Main>
    )
}
export default App;