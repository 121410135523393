
import Swal from 'sweetalert2';



export const logout = () => {
    localStorage.removeItem('token');
};

export const setLogin = (token: string) => {
    localStorage.setItem('token', token);
};

export const getToken: string | null = localStorage.getItem('token');

export const isTokenExpired = (token: string) => {
    const jwtPayload = JSON.parse(atob(token.split(".")[1]))
    return Date.now() >= jwtPayload.exp * 1000;
}


export const showSuccessAlert = (title: string) => {
    Swal.fire({
        title,
        icon: 'success',
        showConfirmButton: false,
        position: 'top-end',
        timer: 3000,

    })
}

export const showToastError = (title: string) => {
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
        customClass: 'custom-toast-error', // Özel CSS sınıfı
    });
}

export const showToastSuccess = (title: string) => {
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title,
        showConfirmButton: false,
        timer: 3000,
        toast: true,
        customClass: 'custom-toast-success', // Özel CSS sınıfı
    });
}

export const isNumeric = (value: string | number): boolean => {
    if (typeof value === 'number') {
        return true; 
    }
    return !isNaN(parseFloat(value)) && isFinite(Number(value));
}