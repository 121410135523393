import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header"
import Menu from "../components/Menu"
import '../assets/global/plugins/font-awesome/css/font-awesome.min.css';
import '../assets/global/plugins/simple-line-icons/simple-line-icons.min.css';
import '../assets/global/plugins/bootstrap/css/bootstrap.min.css';
import '../assets/global/plugins/bootstrap-switch/css/bootstrap-switch.min.css';
import '../assets/global/css/components.min.css';
import '../assets/global/css/plugins.min.css';
import '../assets/layouts/layout/css/layout.css';
import '../assets/layouts/layout/css/themes/darkblue.min.css';
import '../assets/layouts/layout/css/custom.min.css';


interface MyComponentProps {
    children: ReactNode;
  }


const App = ({children}:MyComponentProps) => {
    const navigate = useNavigate();
    
    const getViewPort = () => {
        if (typeof window === 'undefined') {
            return { width: 0, height: 0 };
        }

        const windowInnerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowInnerHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    
        return { width: windowInnerWidth, height: windowInnerHeight };
    };
    useEffect(() => {
        document.title = 'Anasayfa';
        document.body.classList.add('page-header-fixed','page-footer-fixed','page-sidebar-closed-hide-logo','page-content-white');
        adjustPageHeight();
        return () => {
          document.body.classList.remove('page-header-fixed','page-footer-fixed','page-sidebar-closed-hide-logo','page-content-white');
        };
      }, []);

      useEffect(()=>{
       window.addEventListener('resize',adjustPageHeight);
       return()=>{
        window.removeEventListener('resize',adjustPageHeight)
       };
      
      },[])

      const adjustPageHeight = () => {
        const aElement = document.querySelector(".page-content") as HTMLElement;
        const headerElement = document.querySelector(".page-header.navbar.navbar-fixed-top");
        const footerElement = document.querySelector(".page-footer");
        if (headerElement && footerElement && aElement) {
          const headerHeight = headerElement.clientHeight;
          const footerHeight = footerElement.clientHeight;
          aElement.style.minHeight = `${(getViewPort().height)-(headerHeight+footerHeight)}px`;
        }
    };
    
    return (
        <>
        <div className="page-wrapper">
            <Header />
            <div className="clearfix"> </div>
            <div className="page-container">
                <Menu />
                <div className="page-content-wrapper">
                    <div className="page-content">
                        {children}
                    </div>
                </div>
            </div>
            <div className="page-footer">
                <div className="page-footer-inner"> {new Date().getFullYear()} &copy; &nbsp;
                    <a target="_blank" href="https://coozum.com">Coozum</a>
                    
                </div>
                <div className="scroll-to-top">
                    <i className="icon-arrow-up"></i>
                </div>
            </div>
        </div>
       </>
    )
}
export default App;
