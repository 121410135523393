import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { getToken } from "../resources/func";


const App = () =>{
    const navigate = useNavigate();
    useEffect(()=>{
        if(getToken){
            navigate('/anasayfa');
        }else {
            navigate('/giris');
        }
    },[])
    return(
        <div>Test</div>
    )
}
export default App;
