import { axios } from "./service";
import { AxiosError } from 'axios';

export interface LoginResponse {
    AccessToken: string;
    TokenType: string;
    LngKod: number;
    TxtEmail: string;
    TxtKullaniciAd: string;
    BytTur: number;
}

export interface LoginRequest {
    email: string;
    password: string;
}

export const login = async (loginRequest:LoginRequest): Promise<LoginResponse> => {
    try {
        const response = await axios.post<LoginResponse>('api/login',loginRequest,  {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        return response.data;
    } catch (error) {
        
        if (error instanceof AxiosError) {
            // Axios hatası ise işlemleri burada yapabilirsiniz
            const statusCode = error.response?.status || 500;
            const errorMessage = error.response?.data || 'Bir hata oluştu';
            const errorInfo = { statusCode, errorMessage };
            throw errorInfo;
        }

        // Axios dışında bir hata ise doğrudan fırlatın
        throw error;
    }
};