import React, { useEffect, useMemo, useState } from "react";
import { useJwt } from "react-jwt"; // UseJwt, UseJwt olmalı
import { getToken, isTokenExpired, logout } from "../resources/func";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const ApiContext = React.createContext({});

const App = () => {
  const value = useMemo(() => ({}), []);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
        const path = location.pathname;
        if (path == '/' || path == '/giris') {

        } else {
            const token = localStorage.getItem('token');
            if(token){
                if(isTokenExpired(token)){
                    console.log('expired')
                    logout();
                    navigate('giris',{replace:true})
                }
            }
          
        }
   
  }, [location.pathname,navigate]);

  return (
    <ApiContext.Provider value={value}>
      <Outlet />
    </ApiContext.Provider>
  );
};

export default App;
