import { useEffect, useState } from 'react';
import Main from '../components/Main'
import { LogNew, LogResponse, newLog } from '../webservice/index';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { isNumeric, showToastSuccess } from '../resources/func';
import { useParams } from 'react-router-dom';
import { getLog, updateLog } from '../webservice/log';

interface FormValues {
    title: string;
    description: string;
}
const validationSchema = Yup.object({
    title: Yup.string().required('Başlık alanı zorunlu!'),
    description: Yup.string().required('Açıklama alanı zorunlu')
})
const App = () => {
    const { id } = useParams();
    const [allLogs, setAllLogs] = useState<LogResponse[]>([]);
    const [portletOpen, setPortletOpen] = useState(true);
    const closePortlet = () => {
        setPortletOpen(!portletOpen);
    }
    const initialValues: FormValues = {
        title: '',
        description: ''
    }
    useEffect(()=>{
        const token = localStorage.getItem('token');
        if(id){
            if(isNumeric(id)){
                const numberId = parseInt(id);
                if(token){
                    getLog(token,numberId).then(data=>{
                        setAllLogs(data);
                        initialValues.title=data[0].TxtBaslik;
                        initialValues.description=data[0].TxtAciklama;
                    })
                }
            }
        }
    },[]);
    const handleSubmit = (values: FormValues, { setSubmitting ,resetForm}: FormikHelpers<FormValues>) => {
        setSubmitting(false);
        const token = localStorage.getItem('token');
        if(id){
            if(isNumeric(id)){
                const numberId = parseInt(id);
                if(token){
                    const log: LogNew = { comment:values.description, title:values.title };
                    updateLog(token,numberId, log).then(data => {
                        if(data.responseCode===200){
                            showToastSuccess('Log güncelleme başarılı');
                        }
                    })
                }
            }
        }

    }

    return (
        <Main>
            <div className="portlet box blue-chambray">
                <div className="portlet-title">
                    <div className="caption">
                        Log Düzenle
                    </div>
                    <div className="tools">
                        <a onClick={closePortlet} className="collapse" data-original-title="" title=""> </a>
                    </div>
                </div>
                <div className="portlet-body form" style={{ display: portletOpen ? 'block' : 'none' }}>

                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ isSubmitting, errors, touched }) => (
                            <Form className="form-horizontal">
                                <div className="form-body">
                                    <div className={`form-group ${errors.title && touched.title ? 'has-error':''}`}>
                                        <label className="col-md-3 control-label">Başlık <b>*</b></label>
                                        <div className="col-md-4">
                                            <Field type="text" className="form-control" name="title" />
                                            {errors.title && touched.title && <span className="help-block"> {errors.title}</span>}
                                        </div>
                                    </div>
                                    <div className={`form-group ${errors.description && touched.description ? 'has-error':''}`}>
                                        <label className="col-md-3 control-label">Açıklama <b>*</b></label>
                                        <div className="col-md-4">
                                            <Field as="textarea" name="description" style={{ minWidth: '100%' }} rows={5} />
                                            {errors.description && touched.description && <span className="help-block"> {errors.description}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <div className="row">
                                        <div className="col-md-offset-3 col-md-4">
                                            <button disabled={isSubmitting} type="submit" data-loading-text="Yükleniyor..." className="btn blue mt-ladda-btn ladda-button mt-progress-demo2" data-style="slide-right">
                                                <span className="ladda-label">Kaydet</span>
                                            </button>
                                            <button type="reset" className="btn default">Sıfırla</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Main>
    )
}
export default App;