import { axios } from "./service";
import { AxiosError } from 'axios';

export interface LogResponse {
    LngKod: number;
    TxtBaslik: string;
    TxtAciklama: string;
    TrhIslemTarihi: Date;
}

export interface LogNew {
    title: string,
    comment: string
}

export interface LogNewResponse {
    responseCode: number;
    errors: string;
}

export const deleteLog = async (token: string, id: number): Promise<number> => {
    try {
        const response = await axios.delete(`api/log/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.status;
    } catch (error: any) {
        return error.response?.status || 500
    }
}

export const updateLog = async (token: string, id: number,LogNew:LogNew): Promise<LogNewResponse> => {
    try {
        const response = await axios.put(`api/log/guncelle/${id}`,LogNew, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {
            return { responseCode: 200, errors: '' };
        } else {
            return { responseCode: response.status, errors: 'Bir hata oluştu.' };
        }
    } catch (error: any) {
        return { responseCode: error.response?.status || 500, errors: 'Bir hata oluştu.' };
    }
}
export const newLog = async (token: string, LogNew: LogNew): Promise<LogNewResponse> => {
    try {
        const response = await axios.post('api/log/new', LogNew, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {
            return { responseCode: 200, errors: '' };
        } else {
            return { responseCode: response.status, errors: 'Bir hata oluştu.' };
        }
    } catch (error: any) {
        return { responseCode: error.response?.status || 500, errors: 'Bir hata oluştu.' };
    }
}
export const getAllLogs = async (token: string): Promise<LogResponse[]> => {
    try {
        const response = await axios.get<LogResponse[]>('api/logs', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.data;
    } catch (error) {

        if (error instanceof AxiosError) {
            // Axios hatası ise işlemleri burada yapabilirsiniz
            const statusCode = error.response?.status || 500;
            const errorMessage = error.response?.data || 'Bir hata oluştu';
            const errorInfo = { statusCode, errorMessage };
            throw errorInfo;
        }
        // Axios dışında bir hata ise doğrudan fırlatın
        throw error;
    }
};

export const getLog = async (token: string,id:number): Promise<LogResponse[]> => {
    try {
        const response = await axios.get<LogResponse[]>(`api/log/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.data;
    } catch (error) {

        if (error instanceof AxiosError) {
            // Axios hatası ise işlemleri burada yapabilirsiniz
            const statusCode = error.response?.status || 500;
            const errorMessage = error.response?.data || 'Bir hata oluştu';
            const errorInfo = { statusCode, errorMessage };
            throw errorInfo;
        }
        // Axios dışında bir hata ise doğrudan fırlatın
        throw error;
    }
};