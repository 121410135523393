import { Link, useLocation } from 'react-router-dom';
const App = () => {
    const location = useLocation();
    const toggleItem = (event: any) => {
        const liElement = event.currentTarget.closest('li.nav-item');
        if (liElement) {
            const ulElement = liElement.querySelector('ul.sub-menu');
            if (ulElement) {
                ulElement.style.display = ulElement.style.display === 'block' ? 'none' : 'block';
            }
            liElement.classList.toggle('open');
        }
    };
    const checkLocationLi = (loc: string) => {
        return loc === location.pathname ? 'active open' : '';
    }
    const checkLocationUl = (loc: string) => {
        let varMi =location.pathname.includes(loc);

        const displayStyle = varMi ? 'block' : 'none';
        return {
            display: displayStyle
        }
    }
    const menuItems = [
        {
            title: 'Log İşlemleri',
            icon: 'icon-briefcase',
            link:'/log',
            subMenus: [
                { title: 'Liste', link: '/log' },
                { title: 'Ekle', link: '/log/ekle' },
            ],
        },
        // Diğer menü öğeleri buraya eklenir
    ];

    return (
        <div className="page-sidebar-wrapper">

            <div className="page-sidebar navbar-collapse collapse">

                <ul className="page-sidebar-menu page-header-fixed" data-keep-expanded="false" data-auto-scroll="true"
                    data-slide-speed="200">

                    <li className="sidebar-toggler-wrapper hide">
                        <div className="sidebar-toggler">
                            <span></span>
                        </div>
                    </li>

                    <li className="sidebar-search-wrapper">

                    </li>



                    {menuItems.map((menuItem, index) => (
                        <li className="nav-item" key={index}>
                            <a href="javascript:;" className="nav-link nav-toggle" onClick={toggleItem}>
                                <i className={menuItem.icon}></i>
                                <span className="title">{menuItem.title}</span>
                                <span className="arrow"></span>
                            </a>
                            <ul className="sub-menu" style={checkLocationUl(menuItem.link)}>
                                {menuItem.subMenus.map((subMenu, subIndex) => (
                                    <li className={`nav-item ${checkLocationLi(subMenu.link)}`} key={subIndex}>
                                        <Link to={subMenu.link} className="nav-link">
                                            <i className="fa fa-list-ul"></i>
                                            <span className="title">{subMenu.title}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}



                    {/* <li className="nav-item">
                        <a href="javascript:;" className="nav-link nav-toggle" onClick={toggleItem}>
                            <i className="icon-briefcase"></i>
                            <span className="title">Ss İşlemleri</span>
                            <span className="arrow"></span>
                        </a>
                        <ul className="sub-menu">
                            <li className="nav-item {{@PATH=='/log' ? 'active open' : ''}}">
                                <a href="log" className="nav-link">
                                    <i className="fa fa-list-ul"></i><span className="title">Liste</span>
                                </a>
                            </li>
                            <li className="nav-item {{@PATH=='/log/ekle' ? 'active open' : ''}}">
                                <a href="log/ekle" className="nav-link">
                                    <i className="fa fa-plus"></i><span className="title">Ekle</span>
                                </a>
                            </li>
                        </ul>
                    </li> */}

                </ul>

            </div>

        </div>
    )
}

export default App;